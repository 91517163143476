import { InternalAxiosRequestConfig } from 'axios'

export const getDefaultHeaders = async (config: InternalAxiosRequestConfig) => {
  return {
    ...config,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...config.headers,
    },
  } as InternalAxiosRequestConfig
}
