import { UserRoles } from 'domains/user/UserRoles'

export const UserAccessPermissions = {
  SUPPORT_TOOL_ACCESS: 'SUPPORT_TOOL_ACCESS',
  SUPPORT_TOOL_EDIT_PERMISSIONS: 'SUPPORT_TOOL_EDIT_PERMISSIONS',
  SUPPORT_TOOL_CREATE_ADMIN: 'SUPPORT_TOOL_CREATE_ADMIN',
} as const

export type UserAccessPermissions = typeof UserAccessPermissions[keyof typeof UserAccessPermissions]

export const ROLE_BASED_PERMISSIONS: Record<'' | UserRoles, UserAccessPermissions[]> = {
  '': [], // No role
  ROLE_USER_ADMIN: [
    UserAccessPermissions.SUPPORT_TOOL_ACCESS,
    UserAccessPermissions.SUPPORT_TOOL_EDIT_PERMISSIONS,
    UserAccessPermissions.SUPPORT_TOOL_CREATE_ADMIN,
  ],
  ROLE_NORMAL: [],
  ROLE_OPERATOR: [],
  ROLE_DIGITAL_REP: [],
  ROLE_FIELD_REP: [],
}
