import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Container } from 'reactstrap'

import { AppRoutes } from 'constants/appRoutes'

export const ForbiddenPage = () => {
  const navigate = useNavigate()
  const navigateToHome = () => {
    navigate(AppRoutes.root)
  }
  return (
    <Container>
      <h1>403 Forbidden</h1>
      <p>You do not have permission to access this page.</p>
      <p>If you believe this is a mistake, please contact your manager to get permission.</p>
      <Button onClick={navigateToHome}>Go back to Home</Button>
    </Container>
  )
}
