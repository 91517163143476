import React, { useContext, useEffect, useState } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { SystemAccount } from 'domains/account'
import { Address } from 'domains/address'
import { StandardErrorResponse } from 'types/APIResponses'

interface AddressesCardsProps {
  account: SystemAccount
  onSearch: () => void
}

export const AddressesCards = ({ account, onSearch }: AddressesCardsProps) => {
  const { requestStatusRef } = useContext(RequestToasterContext)
  const [addresses, setAddresses] = useState<Address[]>([])
  const onChangeAddressId = (index: number, externalId: string) => {
    setAddresses((state) => {
      const addresses = [...state]
      addresses[index].externalId = externalId
      return addresses
    })
  }
  const onUpdateExternalId = (id: number, externalId: string) => {
    requestStatusRef.current?.startProgress('Updating...', 'secondary')
    API.updateAccountAddressExternalId({ id, externalId, username: account.username })
      .then(() => {
        onSearch()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleGetAddresses = (username: string) => {
    API.getAccountShipAddresses(username)
      .then((response) => {
        setAddresses(response)
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  useEffect(() => {
    handleGetAddresses(account.username)
  }, [account])

  return (
    <ListGroup className="w-75">
      <ListGroupItem className="bg-light">
        ID | Status | External ID | Rel Customer <br />
        Tag - Line 1 - Line 2 - City - State - ZIP - Country
      </ListGroupItem>
      {addresses.map(
        (
          {
            id,
            address1,
            address2,
            city,
            state,
            country,
            externalId,
            customerStatus,
            relCustomerName,
            relCustomerNum,
            tag,
            postalCode,
            isShipping,
            isBilling,
          },
          index,
        ) => {
          const handleChangeAddressId = (event: React.ChangeEvent<HTMLInputElement>) =>
            onChangeAddressId(index, event.target.value)
          const handleUpdateExternalId = () => onUpdateExternalId(id, externalId)
          const addressType = [isShipping && 'Shipping', isBilling && 'Billing']
            .filter(Boolean)
            .join(' & ')

          return (
            <ListGroupItem key={index}>
              {id} | {customerStatus} |
              <input
                type="text"
                value={externalId ?? ''}
                className="small ml-1 mr-1"
                size={10}
                onChange={handleChangeAddressId}
              />
              <button onClick={handleUpdateExternalId} type="button" className="small mr-1">
                Update
              </button>
              {(relCustomerNum || relCustomerName) && (
                <>
                  | {relCustomerNum} {relCustomerName}
                </>
              )}
              {addressType && (
                <>
                  | <strong>{addressType}</strong>
                </>
              )}
              <br />
              {tag} {address1} {address2} {city} {state} {postalCode} {country}
            </ListGroupItem>
          )
        },
      )}
    </ListGroup>
  )
}
