import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Form } from 'reactstrap'

import { API } from 'API'
import { UserAccessPermissionsEnum } from 'constants/userAccessPermissionsEnum'
import { RequestToasterContext } from 'containers/Providers'
import { getUserAuthorities } from 'core/auth'
import { UserAccessPermissions, ROLE_BASED_PERMISSIONS, UserRoles } from 'domains/user'

interface AccessPermissionsControlProps {
  username: string
  role: '' | UserRoles
  permissions: string
}

const ALL_PERMISSIONS = Object.values(UserAccessPermissions)

export const AccessPermissionsControl = ({
  username,
  role,
  permissions,
}: AccessPermissionsControlProps) => {
  const form = useForm<{ permissions: Record<string, boolean> }>({
    mode: 'onSubmit',
  })
  const { requestStatusRef } = useContext(RequestToasterContext)

  const userPermissions = useMemo(
    () => (permissions ? permissions.split(',').map((perm) => perm.trim()) : []),
    [permissions],
  )

  const editablePermissions = useMemo(() => ROLE_BASED_PERMISSIONS[role] || [], [role])
  const [isEditPermissionAllowed, setIsEditPermissionAllowed] = useState<boolean | undefined>(false)

  const initialValues = useMemo(() => {
    return ALL_PERMISSIONS.reduce((acc, permission) => {
      acc[permission] = userPermissions.includes(permission)
      return acc
    }, {} as Record<string, boolean>)
  }, [userPermissions])

  useEffect(() => {
    setIsEditPermissionAllowed(
      getUserAuthorities().includes(UserAccessPermissionsEnum.SUPPORT_TOOL_EDIT_PERMISSIONS),
    )
  }, [])

  useEffect(() => {
    form.reset({ permissions: initialValues })
  }, [initialValues, form])

  const updatePermissions = (data: { permissions: Record<string, boolean> }) => {
    const selectedPermissions = Object.entries(data.permissions)
      .filter(([, isSelected]) => isSelected)
      .map(([permission]) => permission as UserAccessPermissions)

    API.updateAccessPermissions(username, selectedPermissions.join(','))
      .then((status) => {
        if (status === 200) {
          requestStatusRef.current?.showAlert('Updated', 'success')
        }
      })
      .catch(() => {
        requestStatusRef.current?.showAlert('Failed to update permissions', 'danger')
      })
  }

  const isButtonDisabled = editablePermissions.length === 0 || !isEditPermissionAllowed

  return (
    <FormProvider {...form}>
      <Form onSubmit={form.handleSubmit(updatePermissions)}>
        <div>
          {ALL_PERMISSIONS.map((permission) => (
            <div key={permission} className="form-check">
              <input
                type="checkbox"
                id={permission}
                className="form-check-input"
                disabled={!editablePermissions.includes(permission) || !isEditPermissionAllowed}
                {...form.register(`permissions.${permission}`)}
              />
              <label className="form-check-label" htmlFor={permission}>
                {permission}
              </label>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-end mt-3" style={{ gap: '1rem' }}>
          <button type="submit" disabled={isButtonDisabled}>
            Update Access Permissions
          </button>
        </div>
      </Form>
    </FormProvider>
  )
}
