import { protectedApi } from './requester'

export type UserAccessPermissions = string

export const updateAccessPermissions = async (
  username: string,
  permissions: UserAccessPermissions,
): Promise<number> => {
  return protectedApi
    .put<void>(`/user/${username}/permissions`, { permissions })
    .then((response) => response.status)
}
